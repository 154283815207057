import { ComponentStyleConfig } from '@chakra-ui/react';

const variants = {
   light: {
      color: 'white',
      backgroundColor: 'rgba(255, 255, 255, .1)',
   },
   'accent.orange': {
      backgroundColor: 'accent.orange',
      color: 'color.primary',
   },
   'accent.peach': {
      backgroundColor: 'rgba(255, 217, 187, 0.75)',
      color: 'text.secondary',
   },
   eyebrow: {
      fontFamily: 'heading',
      fontSize: 'xs',
      textTransform: 'uppercase',
      background: 'accent.sand',
      py: '0.1rem',
      px: '0.4rem',
   },
};

const sizes = {
   xs: {
      fontSize: 'xxs',
      px: 1,
      py: '0.05rem',
   },
   sm: {
      fontSize: 'xs',
      px: {
         base: 1.5,
         md: 2,
      },
      py: {
         base: 0.5,
         md: 1,
      },
   },
   md: {
      fontSize: 'xs',
      px: 2,
   },
};

const Badge: ComponentStyleConfig = {
   sizes,
   variants,
   defaultProps: {
      size: 'md',
   },
   baseStyle: {
      backgroundColor: 'rgba(18, 23, 35, .08)',
      color: 'text.primary',
      borderRadius: '8px',
      textTransform: 'initial',
      display: 'inline-flex',
      fontSize: 'xs',
      fontWeight: 500,
      alignItems: 'center',
      gap: '5px',
      px: 2,
      py: 1,
   },
};

export default Badge;
